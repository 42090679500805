import { Environment } from '../models/environment.model';
import { ENV } from '@allmyhomes/module-federation';
import { Mixpanel } from 'mixpanel-browser';
import { Injectable, Inject, InjectionToken } from '@angular/core';
import {
  AnalyticsService,
  InitService,
} from '@allmyhomes/angular-analytics-adapter';

/**
 * Important, this service should be replaced by SRE mixpanel tool when available.
 * so no need to add further testing by now.
 */
declare global {
  interface Window {
    dataLayer: unknown[];
    mixpanel: Mixpanel;
  }
}

interface DefaultProperties {
  source_url: string;
  logged_in?: boolean;
}

export interface MixpanelEnvironment {
  apiKey: string;
  optIn: false;
}

export const mixpanelLib = new InjectionToken<string>('Mix panel Lib');

const SERVICE_NAME = 'myAssistant';

@Injectable({ providedIn: 'root' })
export class MixpanelService {
  private defaultProperties: DefaultProperties = {
    source_url: '',
  };

  private apiKey: string;

  constructor(
    @Inject(ENV) private environment: Environment,
    private analyticsService: AnalyticsService,
    private initService: InitService
  ) {
    this.apiKey = this.environment.mixpanel.apiKey;
    this.initializeMixpanel();
  }

  private initAnalytics() {
    const mixpanelConf = {
      mixpanel_project_token: this.environment.mixpanel.apiKey,
      debug: this.environment.mixpanel.debug,
    };

    const authToken = {
      user: {
        id: '',
      },
    };

    this.initService.runInit({
      mfeName: SERVICE_NAME,
      authToken,
      envConfig: mixpanelConf,
    });
  }

  private initializeMixpanel() {
    if (document.referrer) {
      this.defaultProperties.source_url = document.referrer;
    }

    if (this.apiKey !== '') {
      this.defaultProperties.logged_in = false;
      this.initAnalytics();
    }
  }

  public trackEvent(
    eventName: string,
    eventProperties = {},
    timeout = 0
  ): void {
    const properties = { ...this.defaultProperties, ...eventProperties };
    window.setTimeout(() => {
      this.analyticsService.trackGeneric(eventName, properties);
    }, timeout);
  }
}
