// Event Priority taken from here: https://docs.google.com/spreadsheets/d/1slgQ7zq4vOV9eDb--EVq1jjf519pd8b3SIWANl2p5TE

export const eventTypeMap = new Map<string, string>([
  ['compareFlatAdd', '3DESTATE_COMPARE_FLAT_ADD'],
  ['compareFlatRemove', '3DESTATE_COMPARE_FLAT_REMOVE'],
  ['compareOpen', '3DESTATE_COMPARE_OPEN'],
  ['filtersOpenDesktop', '3DESTATE_FILTERS_OPEN_DESKTOP'],
  ['filtersOpenMobile', '3DESTATE_FILTERS_OPEN_MOBILE'],
  ['filtersUpdate', '3DESTATE_FILTERS_UPDATE'],
  ['flatMediaNav', '3DESTATE_FILTERS_MEDIA_NAV'],
  ['flatMediaNav3dBuilding', '3DESTATE_FILTERS_MEDIA_NAV_3D_BUILDING'],
  ['flatMediaNav3dDollhouse', '3DESTATE_FILTERS_MEDIA_NAV_3D_DOLLHOUSE'],
  ['flatMediaNav3dElevation', '3DESTATE_FILTERS_MEDIA_NAV_3D_ELEVATION'],
  ['flatMediaNav3dExterior', '3DESTATE_FILTERS_MEDIA_NAV_3D_EXTERIOR'],
  ['flatMediaNav3dInterior', '3DESTATE_FILTERS_MEDIA_NAV_3D_INTERIOR'],
  ['openFlat', '3DESTATE_OPEN_FLAT'],
  ['openFlatFile', '3DESTATE_OPEN_FLAT_FILE'],
  ['desktopIsNotVisible', '3DESTATE_DESKTOP_IS_NOT_VISIBLE'],
  ['desktopIsVisible', '3DESTATE_DESKTOP_IS_VISIBLE'],
  ['firstInteraction', '3DESTATE_FIRST_INTERACTION'],
  ['openFlatOriginOther', '3DESTATE_OPEN_FLAT_ORIGIN_OTHER'],
]);
