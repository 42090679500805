import { Title } from '@angular/platform-browser';
import { Component, OnInit, Inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { LoadExternalScriptService } from '../services/load-external-script/load-external-script.service';
import { projectNameMap } from '../3destate/projects-name-mapper/project-name-map';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import {
  eventTypeMap,
  MixpanelService,
  Environment,
} from '@online-viewing-workspace/shared/utils';
import { GTM3DEstate } from '../models/gtm-3destate.model';
import { ENV } from '@allmyhomes/module-federation';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare const SM: any;

@Component({
  selector: 'online-viewing-workspace-host-container',
  templateUrl: './host-container.component.html',
  styleUrls: ['./host-container.component.scss'],
})
export class HostContainerComponent implements OnInit {
  private projectKey = this.activatedRoutes.snapshot.params['projectId'];
  private srcScript = this.environment.flatFinder.jsPath;
  private srcStyle = this.environment.flatFinder.cssPath;
  private srcCustomStyle = this.environment.flatFinder.customCssPath;
  private loadedElement = 'online-viewing-app';

  private projectName = projectNameMap.get(this.projectKey) || '';

  constructor(
    private loader: LoadExternalScriptService,
    private activatedRoutes: ActivatedRoute,
    private mixPanelService: MixpanelService,
    private titleService: Title,
    @Inject(ENV) private environment: Environment
  ) {}

  ngOnInit(): void {
    this.setTitle(this.projectName);
    const script = this.loader.loadJsScript(
      new URL(this.srcScript),
      true,
      false
    );
    script.onload = this.onLoadOnlineViewingApp.bind(this);
    this.loader.loadStyles(new URL(this.srcStyle));
    this.loader.loadStyles(new URL(this.srcCustomStyle));
  }

  private setTitle(projectName: string) {
    projectName = projectName ? ` - ${projectName}` : '';
    this.titleService.setTitle(`3D Navigator ${projectName}`);
  }

  private onLoadOnlineViewingApp() {
    /* istanbul ignore next */
    SM.init(
      this.projectKey,
      this.loadedElement,
      {},
      {
        location: true,
        gallery: true,
        showFullscreenButton: true,
        showPrice: true,
        logoMain: true,
      },
      {},
      {
        onAnalyticEvent: (event: GTM3DEstate) => {
          const eventName = eventTypeMap.get(event.event);

          if (eventName != null) {
            this.mixPanelService.trackEvent(eventName, {
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              ...(event.data as any),
            });
          }
        },
      }
    );
  }
}
