import { Environment } from './environment.model';

export const environmentMock: Environment = {
  production: false,
  env: 'develop',

  sentry: {
    dsn: '',
    tracesSampleRate: 0,
    tracingOrigins: ['localhost', /^\//],
  },

  mixpanel: {
    apiKey: 'testApiKey',
    optIn: false,
    debug: false,
  },

  flatFinder: {
    jsPath: new URL('https://maps.googleapis.com/test'),
    cssPath: new URL('https://maps.googleapis.com/test'),
    customCssPath: new URL('https://maps.googleapis.com/test'),
  },
};
