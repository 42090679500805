import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HostContainerComponent } from './host-container.component';
import { RouterModule } from '@angular/router';
import { LoadExternalScriptService } from '../services/load-external-script/load-external-script.service';
import { LoadExternalTrustedScriptService } from '../services/load-external-script/trused-types/load-external-trusted-script.service';

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild([
      { path: '', pathMatch: 'full', component: HostContainerComponent },
    ]),
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],

  declarations: [HostContainerComponent],
  providers: [
    {
      provide: LoadExternalScriptService,
      useClass: LoadExternalTrustedScriptService,
    },
  ],
  exports: [HostContainerComponent],
})
export class FeatureOnlineViewingModule {}
