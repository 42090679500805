import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { init as initSentry, routingInstrumentation } from '@sentry/angular';
import { Integrations } from '@sentry/tracing';
import { default as versionJson } from './version.json';

initSentry({
  dsn: environment.sentry.dsn,
  environment: environment.env, // develop, staging, live
  integrations: [
    new Integrations.BrowserTracing({
      tracingOrigins: environment.sentry.tracingOrigins,
      routingInstrumentation: routingInstrumentation,
    }),
  ],
  release: `${versionJson.version}-${environment.env}`,
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: environment.sentry.tracesSampleRate,
});

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));
