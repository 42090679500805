import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { TrustedTypePolicyFactory } from 'trusted-types';
import { TrustedTypePolicy } from 'trusted-types/lib';
import { LoadExternalScriptService } from '../load-external-script.service';

declare global {
  interface Window {
    trustedTypes: TrustedTypePolicyFactory;
  }
}

const validOrigins = [
  'https://maps.googleapis.com',
  'https://3destatesmartmakietaemb.z6.web.core.windows.net',
];

export const checkOrigin = (url: string) => {
  const parsed = new URL(url, document.baseURI);
  if (validOrigins.includes(parsed.origin)) {
    return url;
  }
  throw new Error(
    'invalid URL: check libs/feature-online-viewing-app/src/lib/services/load-external-script/LoadExternalScriptService.ts'
  );
};

@Injectable({
  providedIn: 'root',
})
export class LoadExternalTrustedScriptService extends LoadExternalScriptService {
  private renderer: Renderer2;
  private dnsPolicy!: TrustedTypePolicy;

  constructor(private rendererFactory: RendererFactory2) {
    super();
    this.renderer = rendererFactory.createRenderer(null, null);
    // @todo proper test
    /* istanbul ignore next */
    if (window.trustedTypes) {
      this.dnsPolicy = window.trustedTypes.createPolicy('cdn-scripts', {
        /* istanbul ignore next */
        createScriptURL(url: string): string {
          return checkOrigin(url);
        },
        /* istanbul ignore next */
        createHTML(url: string): string {
          return checkOrigin(url);
        },
        /* istanbul ignore next */
        createScript(url: string): string {
          return checkOrigin(url);
        },
      });
    }
  }

  /**
   * Append the JS tag to the Document Body.
   * @param src The path to the script
   * @param async
   * @param head bool if we want it on the head or normal at the end of body
   * @returns the script element
   */
  public loadJsScript(src: URL, async = true, head = false): HTMLScriptElement {
    const script = this.renderer.createElement('script');
    script.type = 'text/javascript';
    const srcString = src.toString();

    script.src = this.addToCdnScriptsPolicy().createScriptURL(srcString);
    script.async = async;
    if (head) {
      this.renderer.appendChild(document.head, script);
    } else {
      this.renderer.appendChild(document.body, script);
    }

    return script;
  }

  public loadStyles(src: URL): HTMLStyleElement {
    const styles = this.renderer.createElement('link');

    const srcString = src.toString();
    styles.href = this.addToCdnScriptsPolicy().createHTML(srcString);
    styles.rel = 'stylesheet';
    this.renderer.appendChild(document.head, styles);

    return styles;
  }

  private addToCdnScriptsPolicy() {
    if (!window.trustedTypes) {
      return {
        createScriptURL(url: string): string {
          return checkOrigin(url);
        },
        createHTML(url: string): string {
          return checkOrigin(url);
        },
      };
    }
    // @todo proper test
    /* istanbul ignore next */
    return this.dnsPolicy;
  }
}
