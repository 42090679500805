import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export abstract class LoadExternalScriptService {
  abstract loadJsScript(
    src: URL,
    async: boolean,
    head: boolean
  ): HTMLScriptElement;

  abstract loadStyles(src: URL): HTMLStyleElement;
}
