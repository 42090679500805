import { Environment } from '@online-viewing-workspace/shared/utils';

export const environment: Environment = {
  production: true,
  env: 'live',

  mixpanel: {
    apiKey: 'c58b99e4616e7e97ab91de72030a08fc',
    optIn: true,
    debug: false,
  },

  sentry: {
    dsn: 'https://a014bc5a75384ef79376ba3bbd2ef3eb@sentry.infrastructure.envs.io/91',
    tracesSampleRate: 1.0,
    tracingOrigins: ['localhost', /^\//],
  },

  flatFinder: {
    jsPath: new URL(
      'https://3destatesmartmakietaemb.z6.web.core.windows.net/amh-prod/js/main.js'
    ),
    cssPath: new URL(
      'https://3destatesmartmakietaemb.z6.web.core.windows.net/amh-prod/css/main.css'
    ),
    customCssPath: new URL(
      'https://3destatesmartmakietaemb.z6.web.core.windows.net/v1/custom-css/amh/custom.css'
    ),
  },
};
