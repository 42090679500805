import {
  NgModule,
  APP_INITIALIZER,
  ErrorHandler,
  CUSTOM_ELEMENTS_SCHEMA,
} from '@angular/core';
import { BrowserModule, Title } from '@angular/platform-browser';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { createErrorHandler, TraceService } from '@sentry/angular';
import { Router } from '@angular/router';
import {
  LoadExternalScriptService,
  LoadExternalTrustedScriptService,
  FeatureOnlineViewingModule,
} from '@online-viewing-workspace/online-viewing-app';
import { environment } from '../environments/environment';
import { ENV } from '@allmyhomes/module-federation';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FeatureOnlineViewingModule,
    AppRoutingModule,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    Title,
    {
      provide: ErrorHandler,
      useValue: createErrorHandler({
        showDialog: true,
      }),
    },
    {
      provide: TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {
        // This is intentional
      },
      deps: [TraceService],
      multi: true,
    },
    {
      provide: ENV,
      useValue: environment,
    },
    {
      provide: LoadExternalScriptService,
      useClass: LoadExternalTrustedScriptService,
    },
  ],

  bootstrap: [AppComponent],
})
export class AppModule {}
